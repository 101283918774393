const EVENT_NAME = 'searchWidget.setRecentTrips';

export default function recentTripListener (handler) {
  // Expose the global function "window.dispatchEvent.setRecentTrips"
  if (!window.SearchWidget) {
    window.SearchWidget = {};
  }
  const { SearchWidget } = window;
  SearchWidget.setRecentTrips = (recentTrips) => {
    const event = new CustomEvent(EVENT_NAME, { detail: recentTrips });
    window.dispatchEvent(event);
  };

  // Setup window events required to support multiple widget instances
  const eventListener = ({ detail }) => {
    handler(detail);
  };

  window.addEventListener(EVENT_NAME, eventListener);
  return () => window.removeEventListener(EVENT_NAME, eventListener);
}
